import React from "react";
import { PageLayout } from "../components/page-layout";


export const HomePage: React.FC = () => {
  return (
    <PageLayout >
      <div style={{ textAlign: "center", marginTop: '5rem' }}>
        <p style={{ fontSize: "7rem" }}> Hello in demo</p>
      </div>
    </PageLayout >
  )
};
